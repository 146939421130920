import Content from './Layout/Content/Content';
import { MixProvider } from "./Context/MixContext";
import { ModalProvider } from "./Context/ModalContext";
import PopupModal from "./Components/PopupModal/PopupModal";

import './Assets/webFonts/GuerbetEluciremQuiz.css'
import './App.css';

function App() {
  return (
    <div className="App">
      <MixProvider>
        <ModalProvider>
          <Content />
          <PopupModal />
        </ModalProvider>
      </MixProvider>
      
    </div>
  );
}

export default App;
