import React, { useEffect } from 'react';
// import { useModal, useModalUpdate, useModalContentUpdate } from "../../Components/Context/ModalContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './ModalContent.css'

const ModalContent = (props) => {

    return (
        <div id="modal">
            <div className="modal-header-transparent">
                <div className="close-btn" onClick={props.close}><FontAwesomeIcon icon={faTimes} /></div>
            </div>
            <div id="modal-content" className="modal-content">
                <h1 className="modal-head">Example:</h1>
                <div className="calculation-wrap">
                    <div className="calc-item"><p className="big-p">VOLUME</p></div>
                    <div className="calc-item"><p className="big-p">=</p></div>
                    <div className="calc-item">
                        <div className="division">
                            <p>Dose x Weight</p>
                            <div className="line"></div>
                            <p>Concentration</p>
                        </div>
                    </div>
                    <div className="calc-item"><p className="big-p">=</p></div>
                    <div className="calc-item">
                        <div className="division">
                            <p>0.05mmol/kg x 70.00 kg</p>
                            <div className="line"></div>
                            <p>0.5 mmol/ml</p>
                        </div>
                    </div>
                    <div className="calc-item"><p className="big-p">=</p></div>
                    <div className="calc-item"><p className="big-p result">7&nbsp;mL</p></div>
                </div>
            </div>
        </div>
    );
    
};

export default ModalContent;